// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-changelog-mdx": () => import("./../../../src/pages/changelog.mdx" /* webpackChunkName: "component---src-pages-changelog-mdx" */),
  "component---src-pages-disclaimer-mdx": () => import("./../../../src/pages/disclaimer.mdx" /* webpackChunkName: "component---src-pages-disclaimer-mdx" */),
  "component---src-pages-how-to-mdx": () => import("./../../../src/pages/how-to.mdx" /* webpackChunkName: "component---src-pages-how-to-mdx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-license-mdx": () => import("./../../../src/pages/license.mdx" /* webpackChunkName: "component---src-pages-license-mdx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-roadmap-mdx": () => import("./../../../src/pages/roadmap.mdx" /* webpackChunkName: "component---src-pages-roadmap-mdx" */),
  "component---src-pages-support-mdx": () => import("./../../../src/pages/support.mdx" /* webpackChunkName: "component---src-pages-support-mdx" */)
}

